import React from 'react';
import { Link } from 'gatsby';
import { Container } from '../Container/Container.styles';
import {
  FooterContent, FooterCopyright, FooterWrapper, StyledFooter,
} from './Footer.styles';

export const Footer = () => (
  <StyledFooter>
    <Container>
      <FooterWrapper>
        <FooterContent>
          <h3>Adres</h3>
          <p>
            Niepubliczny Zakład Opieki Zdrowotnej
            <br />
            Wielospecjalistyczna Przychodnia
            <br />
            Bartodzieje Sp. z o.o.
            <br />
            ul.Koszalińska 7, 85-714 Bydgoszcz
          </p>
          <Link to="/rodo">RODO</Link>
          <Link to="/polityka-prywatnosci">Polityka prywatności</Link>
        </FooterContent>
        <FooterContent>
          <h3>Godziny otwarcia</h3>
          <p>
            Przychodnia czynna:
            <br />
            Pn - Pt od 7:15 do 18:00
            <br />
            Poradnia rehabilitacyjna czynna:
            <br />
            Pn - Pt od 7:15 do 19:00
          </p>
        </FooterContent>
        <FooterContent>
          <h3>Kontakt</h3>
          <p>
            Rejestracja ogólna tel:
            <a href="tel:+523650614"> (52) 365 06 14/ 26</a>
          </p>
          <p>
            Tel. komórkowy:
            <a href="tel:+509058301"> 509058301</a>
          </p>
          <p>
            E-mail:
            <a href="mailto:biurobartodzieje@scs.com.pl"> biurobartodzieje@scs.com.pl</a>
          </p>
        </FooterContent>
      </FooterWrapper>
    </Container>
    <FooterCopyright>
      <Container>
        <p>Copyright © 2023 Przychodnia Bartodzieje</p>
      </Container>
    </FooterCopyright>
  </StyledFooter>
);
